<template>
  <b-sidebar
    id="add-new-question-sidebar"
    :visible="isAddNewQuestionSidebarActive"
    bg-variant="white"
    width="50%"
    shadow
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-question-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Add New Question</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
            <validation-provider
              name="Question_Heading"
              :rules="{ required: true, min: 3 }"
              v-slot="validationContext"
            >
              <b-form-group
                id="example-input-group-2"
                label="Question Heading"
                label-for="example-input-2"
              >
                <b-form-input
                  id="example-input-2"
                  name="example-input-2"
                  v-model="questiondata.title"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-2-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-2-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              name="Question_Type"
              :rules="{ required: true, min: 3 }"
              v-slot="validationContext"
            >
              <b-form-group
                id="example-input-group-3"
                label="Question Type"
                label-for="example-input-3"
              >
                <b-form-select
                  id="example-input-3"
                  name="example-input-3"
                  v-model="questiondata.questionType"
                  :options="questiontype"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-3-live-feedback"
                ></b-form-select>

                <b-form-invalid-feedback id="input-3-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div style="display: flex; flex-direction: row; overflow: auto">
              <span
                style="
                  border: 1px solid black;
                  width: 70px;
                  height: auto;
                  position: relative;
                "
                v-for="(images, index) in questiondata.Question_images"
                :key="index"
              >
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  style="
                    background: red;
                    width: 17px;
                    height: 17px;
                    position: absolute;
                    right: 0;
                  "
                  @click="deleteimage(index)"
                />
                <img style="width: 70px; height: 70px" :src="images" />
              </span>
            </div>
            <br />
            <br />
            <b-button
              variant="primary"
              @click="showgallery"
              v-if="buttonremove"
            >
              <!-- <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            /> -->
              <span class="d-none d-sm-inline">Question Image gallery</span>
              <!-- <feather-icon icon="EditIcon" class="d-inline d-sm-none" /> -->
            </b-button>
            <b-button
              style="margin-left: 10px"
              variant="primary"
              @click="$refs.refInputEl.click()"
              v-if="buttonremove"
            >
              <input
                ref="refInputEl"
                type="file"
                multiple
                accept="image/x-png,image/gif,image/jpeg"
                class="d-none"
                @input="inputImage"
              />
              <span class="d-none d-sm-inline">Upload</span>
              <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
            </b-button>

            <!-- <br />
          <br />

          <img style="width: 100px; height: 80px" :src="ClassData.class_img" /> -->
            <br />
            <br />
            <div style="width: 100%; position: relative">
              <b-form-input
                v-if="Imageshow"
                v-model="searchQuer"
                class="d-inline-block mr"
                placeholder="Search..."
              />
              <feather-icon
                v-if="Imageshow"
                icon="XIcon"
                @click="hideimage"
                style="
                  background: red;
                  width: 17px;
                  height: 17px;
                  position: absolute;
                  right: 0;
                  bottom: -2%;
                "
              />
            </div>
            <div
              style="
                border: 1px solid black;
                width: 100%;
                height: 230px;
                margin-bottom: 70px;
                overflow: auto;
              "
              v-if="Imageshow"
            >
              <span v-for="grade in getImage()" :key="grade.img_id">
                <img
                  v-if="Imageshow"
                  style="width: 70.7px; height: 80px"
                  :src="grade.gallery_img"
                  @click="galleryimgquestion(grade.gallery_img)"
                />
              </span>
            </div>

            <div
              v-for="(option_nodata, index) in option_array_data"
              :key="index + 1"
              style="margin-left: 10px"
            >
              <h5>
                Option {{ index + 1 }}
                <b-button
                  style="
                    margin-top: 2px;
                    width: 100px;
                    height: 14px;
                    font-size: 10px;
                  "
                  @click="deletedatafromarray(index)"
                  variant="danger"
                  >delete</b-button
                >
                <!-- <b-badge
                  pill
                  variant="danger"
                 
                  >delete</b-badge
                > -->
              </h5>
              <h6 style="font-size: 12px">Title</h6>
              <b-form-input
                v-model="option_nodata.text"
                id="title"
                name=""
              ></b-form-input>
              <img
                style="width: 150; height: 100px"
                :src="option_nodata.image"
              />

              <b-button
                style="margin-left: 10px"
                variant="primary"
                @click="changefunction(index)"
              >
                <input
                  :ref="'ref_Input' + index"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  class="d-none"
                  @input="optionimage"
                />
                <span class="d-none d-sm-inline">Upload</span>
                <!-- <feather-icon icon="EditIcon" class="d-inline d-sm-none" /> -->
              </b-button>

              <h6 style="font-size: 12px">Answere</h6>
              <b-form-input
                v-model="option_nodata.is_correct"
                id="Answere"
                name=""
              ></b-form-input>
            </div>
            <b-button
              style="
                margin-top: 2px;
                width: 100px;
                height: 14px;
                font-size: 10px;
              "
              @click="addnewoption"
              variant="primary"
              >add option</b-button
            >
            <br />
            <br />
            <h6>Background</h6>
            <b-form-select
              v-on:change="changebgtype(selected_bg_type)"
              v-model="selected_bg_type"
              :options="background_type"
            ></b-form-select>
            <div style="margin-top: 12px" v-if="bg_text_type">
              <h6>Question Color</h6>
              <!-- <b-form-input
                id="example-input-2"
                name="example-input-2"
                v-model="questiondata.Question_color"
              ></b-form-input> -->
              <color-picker
                theme="light"
                :color="color"
                :sucker-hide="false"
                :sucker-canvas="suckerCanvas"
                :sucker-area="suckerArea"
                @changeColor="changeColor"
                @openSucker="openSucker"
              />
            </div>

            <div v-if="bg_img_type">
              <img
                style="width: 200px; height: 150px"
                :src="questiondata.background"
              />
              <br />
              <br />
              <b-button
                variant="primary"
                @click="showgalleryimage"
                v-if="removebutton"
              >
                <!-- <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            /> -->
                <span class="d-none d-sm-inline">BackgroundImage gallery</span>
                <!-- <feather-icon icon="EditIcon" class="d-inline d-sm-none" /> -->
              </b-button>
              <b-button
                style="margin-left: 10px"
                variant="primary"
                @click="$refs.refInput.click()"
                v-if="removebutton"
              >
                <input
                  ref="refInput"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  class="d-none"
                  @input="ImageRenderer"
                />
                <span class="d-none d-sm-inline">Upload</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>

              <!-- <br />
          <br />

          <img style="width: 100px; height: 80px" :src="ClassData.class_img" /> -->
              <br />
              <br />
              <div style="width: 100%; position: relative">
                <b-form-input
                  v-if="showImage"
                  v-model="searchQuery"
                  class="d-inline-block mr"
                  placeholder="Search..."
                />
                <feather-icon
                  v-if="showImage"
                  icon="XIcon"
                  @click="hideimagegallery"
                  style="
                    background: red;
                    width: 17px;
                    height: 17px;
                    position: absolute;
                    right: 0;
                    bottom: -2%;
                  "
                />
              </div>

              <div
                style="
                  border: 1px solid black;
                  width: 100%;
                  height: 230px;
                  margin-bottom: 70px;
                  overflow: auto;
                "
                v-if="showImage"
              >
                <span v-for="grade in getImages()" :key="grade.img_id">
                  <img
                    v-if="showImage"
                    style="width: 89.7px; height: 80px"
                    :src="grade.gallery_img"
                    @click="galleryimg(grade.gallery_img)"
                  />
                </span>
              </div>
            </div>

            <validation-provider
              name="Question_voiceovers"
              v-slot="validationContext"
            >
              <b-form-group
                id="example-input-group-6"
                label="Question voiceovers"
                label-for="example-input-6"
              >
                <b-form-input
                  id="example-input-6"
                  name="example-input-6"
                  v-model="questiondata.Question_voiceovers"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-6-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-6-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- <feather-icon
                  @click="preview_question(question_data._id)"
                  
                  height="20px"
                  width="20px"
                  icon="EyeIcon"
                /> -->
            <b-button v-b-modal.modal type="submit" variant="primary"
              >submit</b-button
            >
            <b-button class="ml-2" @click="resetForm()">Cancel</b-button>
          </b-form>
        </validation-observer>
      </div>
      <!-- <b-modal id="modal-sm" size="sm" title="Json Preview"
        >Hello Small Modal!</b-modal
      > -->
      <b-modal hide-footer id="modal" size="lg" centered title="Json Preview">
        <!-- <div
          contenteditable="true"
          style="width: 100%; height: auto; overflow: hidden"
        > -->
        <!-- <div>
            {{ post_question_data }}
            <br /> -->

        <!-- <input :v-model="jsondata"/> -->
        <!-- <span>{{post_question_data}}</span> -->
        <!-- <text style="width: 100%; height: 300px">{{ post_question_data }}</text> -->
        <!-- </div> -->

        <textarea
          style="width: 100%; height: 300px"
          v-model="post_question_data"
        ></textarea>

        <span>Question_images:</span>
        <span v-for="images in questiondata.Question_images" :key="images">
          <img
            v-b-tooltip.hover.middle="imageTooltipText"
            @mouseover="mouseOver(images)"
            style="width: 50px; height: 50px"
            :src="images"
          />
        </span>
        <div>
          <span>Option image:</span>
          <!-- {{option_array_data.image}} -->
          <span v-for="option in option_array_data" :key="option.id">
            <img
              v-b-tooltip.hover.middle="imageTooltipoption"
              @mouseover="mouseoption(option.image)"
              style="width: 50px; height: 50px"
              :src="option.image"
            />
          </span>
        </div>
        <span>Background image:</span>
        <!-- <span v-for="images in questiondata.Question_images" :key="images"> -->

        <img
          v-b-tooltip.hover.middle="imageTooltipbg"
          @mouseover="mousebgimage(questiondata.background)"
          style="width: 50px; height: 50px"
          :src="questiondata.background"
        />
        <!-- </span> -->
        <div class="modal-footer">
          <button
            type="button"
            @click="submitjsonmodel()"
            class="btn btn-primary"
          >
            Submit
          </button>
          <button
            type="button"
            @click="closejsonmodel()"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
        <!-- <modal-footer visible=false>
          <b-button variant="primary">submit</b-button>
          <b-button class="ml-2">Cancel</b-button>
        </modal-footer> -->
      </b-modal>

      <!-- BODY -->
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormSelect,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required, alphaNum, email } from "@validations";
import vSelect from "vue-select";
import axios from "axios";
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import colorPicker from "@caohenghu/vue-colorpicker";
// import VueJsonPretty from "vue-json-pretty";
// import "vue-json-pretty/lib/styles.css";
export default {
  // mixins: [validationMixin],
  components: {
    // VueJsonPretty,
    BSidebar,
    BCol,
    BForm,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    VBTooltip,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    colorPicker,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  model: {
    prop: "isAddNewQuestionSidebarActive",
    event: "update:is-add-question-user-sidebar-active",
  },
  props: {
    isAddNewQuestionSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      imageTooltipoption: "",
      imageTooltipbg: "",
      imageTooltipText: "",
      searchQuery: "",
      searchQuer: "",
      // showjsondata: false,
      color: "#59c7f9",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      selected_bg_type: "",
      showImage: false,
      removebutton: true,
      Imageshow: false,
      buttonremove: true,
      bg_text_type: false,
      bg_img_type: false,

      option_array_data: [],
      add_new_option: {
        id: null,
        text: "",
        image:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
        is_correct: null,
      },
      optionindex: "",
      optiondata: [],
      questiondata: {
        Answere: [],
        title: "",
        questionType: "",
        backgroundType: "",
        background:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
        Question_images: [],
        Question_voiceovers: "",
        Question_color: "",
      },
      questiontype: [
        { value: "single-select", text: "Single Select" },
        { value: "single-select-two", text: "Single Select two" },
        { value: "multi-selectfour", text: "Multi Select four" },
        { value: "multi-select", text: "Multi Select" },
        { value: "count-click", text: "Count Click" },
        { value: "count-object", text: "Count-Object" },
        { value: "correct-symbols", text: "correct-symbols" },
        { value: "missing-number", text: "missing-number" },
        { value: "least-greatest", text: "least-greatest" },
        { value: "addsubtract-click", text: "addsubtract-click" },

        { value: "before-word", text: "before-word" },
        { value: "word-problems-click", text: "word-problems-click" },
        { value: "before-word-english", text: "before-word-english" },
        { value: "arrange-sentence", text: "arrange-sentence" },
        { value: "multi-select-text-four", text: "multi-select-text-four" },
        { value: "multi-select-text", text: "multi-select-text" },
        { value: "order-letter-dragdrop", text: "order-letter-dragdrop" },
        { value: "sentences", text: "sentences" },
        { value: "arrange-sentence", text: "arrange-sentence" },
        // { value: "addsubtract-click", text: "addsubtract-click" },
      ],
      background_type: [
        { value: "background_color", text: "background color" },
        { value: "background_image", text: "background image" },
      ],

      bgImage_name: "",
      quesImage_name: [],
      form: {
        title: "",
        questionType: "",

        Answere: [],
        background:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
        Question_images: [],
        Question_voiceovers: "",
        Question_color: null,
        backgroundType: "",
      },
      table_data: {},
      post_question_data: "",
    };
  },
  created() {
    this.gettabledata();
  },
  methods: {
    getImages() {
      if (this.searchQuery.trim().length > 0) {
        return this.table_data.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.image_name?.toLowerCase().includes(v));
        });
      } else {
        return this.table_data;
      }
    },
    getImage() {
      if (this.searchQuer.trim().length > 0) {
        return this.table_data.filter((item) => {
          return this.searchQuer
            .toLowerCase()
            .split(" ")
            .every((v) => item.image_name.toLowerCase().includes(v));
        });
      } else {
        return this.table_data;
      }
    },
    mousebgimage(a) {
      // console.log(a);
      this.imageTooltipbg = a;
    },
    mouseoption(a) {
      // console.log(a);
      this.imageTooltipoption = a;
    },
    mouseOver(a) {
      // alert(0)
      this.imageTooltipText = a;
    },
    deleteimage(index) {
      this.questiondata.Question_images.splice(index, 1);
    },
    submitjsonmodel() {
      try {
        this.post_question_data = JSON.parse(this.post_question_data);
      } catch (e) {
        alert("syntax error");
        return false;
      }

      // console.log(this.post_question_data);
      this.$emit("clickaddnewquestion", this.post_question_data);
      this.$root.$emit("bv::hide::modal", "modal");
      this.$root.$emit("bv::toggle::collapse", "add-new-question-sidebar");
    },
    closejsonmodel() {
      // alert(0)
      this.$root.$emit("bv::hide::modal", "modal");
      this.$root.$emit("bv::toggle::collapse", "add-new-question-sidebar");
    },
    changeColor(color) {
      const { r, g, b, a } = color.rgba;
      this.questiondata.Question_color = color.hex;
      this.questiondata.backgroundType = "color";
      // console.log(this.questiondata.Question_color);
      this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    openSucker(isOpen) {
      if (isOpen) {
      } else {
      }
    },
    changebgtype(bg_type) {
      if (bg_type == "background_color") {
        (this.bg_text_type = true), (this.bg_img_type = false);
      } else {
        (this.bg_text_type = false), (this.bg_img_type = true);
      }
    },
    deletedatafromarray(delete_index) {
      this.option_array_data.splice(delete_index, 1);
    },
    changefunction(e) {
      this.optionindex = e;
      var target = "ref_Input" + e;
      //  console.log(this.$refs[target]);
      var x = this.$refs[target];
      //  console.log(x[0]);
      x[0].click();
      //.$el.click();
    },
    addnewoption() {
      // console.log(this.add_new_option.id);
      this.add_new_option.id = this.add_new_option.id + 1;
      // console.log(this.add_new_option.id);
      this.option_array_data.push(this.add_new_option);
      (this.add_new_option = {
        id: this.add_new_option.id,
        text: "",
        image:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
        is_correct: null,
      }),
        console.log(this.option_array_data);
    },

    onSubmit() {
      var a = this.option_array_data.length;
      //  console.log(a);
      for (let index = 0; index < a; index++) {
        this.questiondata.Answere.push(
          this.option_array_data[index].is_correct
        );
      }
      this.questiondata.level_id = this.$route.params.levelId;
      this.questiondata.optiondata = this.option_array_data;
      this.post_question_data = this.questiondata;
      this.post_question_data = JSON.stringify(
        this.post_question_data,
        null,
        2
      );
      // console.log(this.post_question_data);

      // var questionimg = this.post_question_data['Question_images'];
      // console.log(questionimg)
    },
    handleFileUpload() {
      this.questiondata.Question_voiceovers = this.$refs.file.files[0];
      // console.log(this.questiondata.Question_voiceovers);
    },

    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/zape-cms/gallery", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          // console.log(response.data);
          this.table_data = get_response.data;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    // arraypushoption() {
    //   this.optiondata.push(this.questiondata.options);
    //   console.log(this.optiondata);
    //   this.questiondata.options = "";
    // },
    resetForm() {
      // this.$root.$emit("bv::toggle::collapse", "add-new-question-sidebar");
      this.questiondata = {
        title: "",
        questionType: "",
        optiondata: [],

        Answere: [],
        background:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
        Question_images: [],
        Question_voiceovers: "",
        Question_color: "",
        backgroundType: "",
      };
      this.searchQuery = "";
      this.searchQuer = "";
      this.option_array_data = [];
      this.add_new_option = {
        id: null,
        text: "",
        image:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
        is_correct: null,
      };
      this.bg_text_type = false;
      this.bg_img_type = false;
      (this.selected_bg_type = ""),
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
    },
    showgalleryimage() {
      this.showImage = true;
      this.removebutton = false;
    },
    hideimagegallery() {
      this.showImage = false;
      this.removebutton = true;
    },
    galleryimg(data) {
      this.questiondata.background = data;
      this.questiondata.backgroundType = "image";
      this.showImage = false;
      this.removebutton = true;
    },
    showgallery() {
      this.Imageshow = true;
      this.buttonremove = false;
    },
    hideimage() {
      this.Imageshow = false;
      this.buttonremove = true;
    },
    galleryimgquestion(data) {
      //  this.questiondata.Question_images = [];
      // console.log(this.questiondata.Question_images);
      this.questiondata.Question_images.push(data);
      // console.log(this.questiondata.Question_images);
    },

    ImageRenderer(data) {
      this.bgImage_name = data.target.files[0];

      if (data.target.files && data.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const a = [];
          a.push(e.target.result);
          axios
            .post(process.env.VUE_APP_API_URL + "/basetourl", a)
            .then((response) => {
              // console.log(response.data);
              this.questiondata.background = response.data;
              this.questiondata.backgroundType = "image";
            });

          // (this.subject_data.subject_img);
        };
        reader.readAsDataURL(data.target.files[0]);
      }
    },
    inputImage(input) {
      this.questiondata.Question_images = [];
      // console.log(this.img_name);
      if (input.target.files && input.target.files) {
        const files = input.target.files;
        files.forEach((file) => {
          this.quesImage_name.push(file);
          const reader = new FileReader();
          reader.onload = (e) => {
            // this.questiondata.Question_images.push(e.target.result);
            const a = [];
            a.push(e.target.result);
            axios
              .post(process.env.VUE_APP_API_URL + "/basetourl", a)
              .then((response) => {
                // console.log(response.data);
                this.questiondata.Question_images.push(response.data);
              });
            // console.log(this.questiondata.Question_images);
          };
          reader.readAsDataURL(file);
          //    console.log(file);
        });
      }
    },
    optionimage(option_img) {
      // console.log(option_img, "XXXXXXXX");
      // this.quesImage_name = input.target.files[0];

      if (option_img.target.files && option_img.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const a = [];
          a.push(e.target.result);
          axios
            .post(process.env.VUE_APP_API_URL + "/basetourl", a)
            .then((response) => {
              // console.log(response.data);
              this.option_array_data[this.optionindex].image = response.data;
            });

          // console.log(e.target.result);
          // (this.subject_data.subject_img);
        };
        reader.readAsDataURL(option_img.target.files[0]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
