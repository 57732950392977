<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="6" md="6">
            <div>
              <b-form-group
                label="Search Question"
                v-if="addDeleteQuestionHideButton == true"
              >
                <b-form-input
                  @input="getDeletedQuestion"
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
              </b-form-group>
              <!-- <b-button
                variant="primary"
                @click="isAddNewTypeSidebarActive = true"
              >
                <span class="text-nowrap">Add Question</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- <feather-icon icon="EyeIcon" class="d-inline d-sm-none" /> -->
      <b-overlay :show="show" rounded="sm">
        <b-table
          v-if="addDeleteQuestionHideButton == true"
          :items="table_data"
          responsive
          :fields="fields"
          class="mb-0"
          id="custom-table"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <!-- {{index]}} -->
          <template #cell(questionNo)="data">
            <div class="d-flex align-items-center">
              <!-- {{ data.item.grade }} -->
              <div>
                <div class="font-weight-bolder">
                  {{ data.item.questionNo }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(title)="data">
            <div class="d-flex align-items-center">
              <!-- {{ data.item.grade }} -->
              <div>
                <div class="font-weight-bolder">
                  {{ data.item.title }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(questionType)="data">
            <div class="d-flex align-items-center">
              <!-- {{ data.item.grade }} -->
              <div>
                <div class="font-weight-bolder">
                  {{ data.item.questionType }}
                </div>
              </div>
            </div>
          </template>
          <!-- <template #cell(background)="data">
            <div class="d-flex align-items-center">
              <div v-if="data.item.backgroundType == 'image'">
                <img
                  style="width: 50px; height: 50px"
                  :src="data.item.background"
                />
              </div>
              <div v-else>
                {{ data.item.Question_color }}
              </div>
            </div>
          </template> -->
          <template #cell(userName)="data">
            <div class="d-flex align-items-center">
              <!-- {{ data.item.grade }} -->
              <div>
                {{ data.item.userName }}
              </div>
            </div>
          </template>
          <template #cell(dayDate)="data">
            <div class="d-flex align-items-center">
              <!-- {{ data.item.grade }} -->
              <div>
                {{ data.item.dayDate }}
              </div>
            </div>
          </template>
          <template #cell(categoryName)="data">
            <div class="d-flex align-items-center">
              <!-- {{ data.item.grade }} -->
              <div>{{ data.item.categoryName }} {{ data.item.levelNo }}</div>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="d-flex align-items-center">
              <!-- {{ data.item.grade }} -->
              <div>
                <b-dropdown variant="link" no-caret>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    @click="preview_question(data.item)"
                    v-b-modal.modal-lg
                  >
                    <feather-icon height="20px" width="20px" icon="EyeIcon" />
                    <span class="align-middle ml-50">Preview</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="retoreDeleteQuestion(data.item._id)">
                    <feather-icon icon="RefreshCwIcon" />
                    <span class="align-middle ml-50">Restore</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="deleteOldQuestion(data.item._id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
        </b-table>
        <div style="display: flex; flex-direction: row-reverse">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="custom-table"
            size="sm"
          ></b-pagination>
        </div>
      </b-overlay>
      <b-modal id="modal-json" size="lg" centered title="Json Preview">
        <div style="overflow: hidden">
          <textarea
            style="width: 100%; height: 300px"
            v-model="jsonShowdata"
          ></textarea>
        </div>
      </b-modal>
      <b-modal
        id="modal-lg"
        hide-footer
        size="lg"
        centered
        title="Question Preview"
      >
        <div>
          <div
            v-for="(preview, index) in previewbackground"
            :key="index + 'gddsgsd'"
          >
            <div v-if="preview.data == 'image'">
              <img class="model_backgroundimage" :src="preview.values" />
            </div>
            <div
              v-else
              :style="
                'background: ' +
                preview.values +
                ';height: 100%;width: 100%;position: absolute;top: 0;left: 0;'
              "
            ></div>
          </div>
          <div class="modal_content">
            <div v-for="(preview, index) in previewTitle" :key="index + 'g24'">
              <div
                v-for="(preview, index) in previewQuestionType"
                :key="index + 'gd09'"
              >
                <h3>
                  {{ preview.data }}
                </h3>
              </div>
              <h3
                style="
                  text-align: center;
                  background-color: #ffa500;
                  color: white;
                  margin-left: 10%;
                  margin-right: 10%;
                  width: 80%;
                  font-size: 1.8rem;
                  height: auto;
                  border-radius: 15px;
                "
              >
                {{ preview.data }}
              </h3>
            </div>
            <div>
              <b-row
                v-for="(preview, index) in previewImage"
                :key="index + '09'"
              >
                <b-col cols="3" class="text-center text-white">
                  <img
                    style="height: 100px; margin: 5px 0"
                    :src="preview.data"
                  />
                </b-col>
              </b-row>
            </div>
            <div
              v-for="(preview, index) in previewArray"
              :key="index + 'gddko'"
            >
              <span v-if="preview.fieldType == 'image'">
                <img style="width: 150px; height: 150px" :src="preview.data" />
              </span>
              <span v-if="preview.fieldType == 'text'">
                <h6 style="margin-left: 10px; color: green">
                  {{ preview.data }}
                </h6>
              </span>
            </div>
            <b-row>
              <b-col
                :cols="12 / preview.data.length + 1"
                v-for="(preview, index) in previewObject"
                :key="index + 'K12'"
                class="text-center text-white"
              >
                <div v-if="preview.data.fieldType == 'image'">
                  <img
                    style="width: 150px; height: 150px"
                    :src="preview.data.value"
                  />
                </div>
                <div v-if="preview.data.fieldType == 'text'">
                  <div style="background: black">
                    {{ preview.data.value }}
                  </div>
                </div>
                <div v-if="preview.data.fieldType == 'text'">
                  <div style="background: green">
                    {{ preview.data.isCorrect }}
                  </div>
                </div>
                <div v-if="preview.data.fieldType == 'image'">
                  <div style="background: green">
                    {{ preview.data.isCorrect }}
                  </div>
                </div>
              </b-col>
            </b-row>
            <div v-for="(preview, index) in previewText" :key="index + 'L1'">
              <h6 v-if="preview.fieldType == 'text'">
                {{ preview.data }}
              </h6>
            </div>
          </div>
        </div>
        <div class="modal_content">
          <div class="modal-footer">
            <button @click="backquestion" class="btn btn-primary">back</button>
            <button @click="nextquestion" class="btn btn-primary">next</button>
            <button type="button" v-b-modal.modal-json class="btn btn-primary">
              Open Json
            </button>
            <button
              type="button"
              @click="closejsonmodel()"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </b-modal>
    </b-card>
    <!-- {{ table_data.length}} -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTableSimple,
  BThead,
  BTr,
  BOverlay,
  BFormGroup,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormFile,
  VBTooltip,
} from "bootstrap-vue";
// import { avatarText } from "@core/utils/filter";

import vSelect from "vue-select";
// import { onUnmounted } from "@vue/composition-api";
// import store from "@/store";
// import useInvoicesList from "./useInvoiceList";
import AddNewQuestion from "./app/list/users-list/AddNewQuestion.vue";
import AddEditQuestion from "./app/list/users-list/AddEditQuestion.vue";
import route from "@/router";
import axios from "axios";
import AddEditType from "./app/list/users-list/AddEditType.vue";
import AddNewType from "./app/list/users-list/AddNewType.vue";
import { BBreadcrumb } from "bootstrap-vue";
export default {
  components: {
    AddNewQuestion,
    AddEditQuestion,
    AddNewType,
    BBreadcrumb,
    AddEditType,
    BCard,
    BRow,
    BFormGroup,
    VBTooltip,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BOverlay,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    BFormFile,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: [
        {
          text: localStorage.getItem("className"),
          // name: "Classes",
          to: { name: "Classes" },
        },
        {
          text: localStorage.getItem("subjectName"),
          // name: "Classes",
          to: { name: "Classes" },
        },
        {
          text: localStorage.getItem("categoryName"),
          //  name: "Classes",
          to: { name: "Classes" },
        },
        {
          text: "Levels_" + localStorage.getItem("level"),
          to: { path: "/categorylevels/" + localStorage.getItem("categoryId") },
        },
        {
          text: "Question",
          active: true,
        },
      ],
      perPage: 10,
      currentPage: 1,
      show: false,
      fields: [
        { key: "questionNo", label: "No" },
        { key: "title", label: "Heading" },
        { key: "questionType", label: "Question type" },
        // { key: "background", label: "Background" },
        { key: "userName", label: "User" },
        { key: "dayDate", label: "Delete Time" },
        { key: "categoryName", label: "Category" },
        { key: "action", label: "Action" },
      ],

      isAddEditTypeSidebarActive: false,
      imageTooltipText: "",
      currentindextable: "",
      imageTooltipbg: "",
      imageTooltipText: "",
      jsonShowdata: "",
      editdata: {},
      isAddNewTypeSidebarActive: false,
      isAddNewQuestionSidebarActive: false,
      isAddEditQuestionSidebarActive: false,
      searchQuery: "",
      table_data: {},
      base_url: null,

      modelbgimage:
        "https://zape02.s3.ap-southeast-1.amazonaws.com/61cb4bb717ea361cb4bb717ea4.png",
      statusOptions: [
        { label: "Plants and animals", value: "plantsandanimals" },
        { label: "Foods", value: "foods" },
        { label: "Missing number", value: "missingnumber" },
      ],
      routedata: this.$route.params.levelId,
      indexdata: {
        id: "",
      },
      previewdata: {},
      deletequestion: {
        id: "",
      },
      new_index: "",
      previewText: [],
      previewImage: [],
      previewArray: [],
      previewbackground: [],
      previewTitle: [],
      previewObject: [],
      addDeleteQuestionHideButton: false,
      previewQuestionType: [],
      table_: {},
    };
  },
  computed: {
    rows() {
      // return 39;
      return this.table_data.length;
    },
  },
  created() {
    // console.log(this.$route.params.levelId);
    this.gettabledata();
    // console.log(this.gettabledata);
  },
  methods: {
    deleteOldQuestion(i) {
      // console.log(i,'gsdgsd')
      // return 'fas';
      var a = {
        id: i,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/deleted-question/delete", a, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          // this.gettabledata();
          this.gettabledata();
        });
    },
    retoreDeleteQuestion(index) {
      var a = {
        id: index,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/deleted-question/restore", a, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          // this.gettabledata();
          this.gettabledata();
        });
    },
    mousebgimage(a) {
      // console.log(a);
      this.imageTooltipbg = a;
    },
    mouseoption(a) {
      // console.log(a);
      this.imageTooltipoption = a;
    },
    mouseOver(a) {
      // alert(0)
      this.imageTooltipText = a;
    },
    closejsonmodel() {
      this.new_index = "";
      this.$root.$emit("bv::hide::modal", "modal-lg");
    },
    backquestion() {
      // alert(this.currentindextable);
      if (this.currentindextable != "0") {
        this.currentindextable = this.currentindextable - 1;
        this.new_index = this.currentindextable;
        this.previewdata = this.table_data[this.currentindextable];
        this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
        this.preview_question(this.currentindextable);
      }
    },
    nextquestion() {
      if (this.currentindextable == 0) {
        // console.log(0);
        this.currentindextable = this.currentindextable + 1;
        this.new_index = this.currentindextable;
        this.previewdata = this.table_data[this.currentindextable];
        this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
        this.preview_question(this.currentindextable);
        // console.log(this.new_index);
      } else {
        var a = this.table_data;
        // console.log(a.length)
        if (this.currentindextable < a.length - 1) {
          this.currentindextable = this.currentindextable + 1;
          this.new_index = this.currentindextable;
          this.previewdata = this.table_data[this.currentindextable];
          this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
          //  console.log(this.previewdata);
          this.preview_question(this.currentindextable);
          // console.log(this.new_index);
        } else {
        }
      }
    },
    preview_question(index) {
      // console.log('sfsaf',index);
      // return  ' done';
      (this.previewText = []),
        (this.previewImage = []),
        (this.previewArray = []),
        (this.previewbackground = []),
        (this.previewTitle = []),
        (this.previewObject = []),
        (this.previewQuestionType = []);
      // alert(index);
      this.previewdata = index;
      this.previewdata = JSON.stringify(this.previewdata);
      this.previewdata = JSON.parse(this.previewdata.replace(/null/g, '""'));
      this.converttojson(this.previewdata);
      this.currentindextable = index;
      // console.log(this.previewdata)
      // return "done"
      // objectdata = objectdata.replace(/null/g, '""');questionType
      var a = this.previewdata;
      var changeID = a._id;
      a["question_id"] = changeID;
      var backgrounddata = a.background;
      var questionTitle = a.title;
      var backgroundType = a.backgroundType;
      // console.log("background", backgrounddata);
      delete a.title;
      var Question_Type = a.questionType;
      // delete a._id;
      delete a.backgroundType;
      delete a.backgroundImage;
      delete a.backgroundColor;
      delete a.background;
      delete a._id;
      delete a.id;
      delete a.level_id;
      delete a.questionType;
      delete a.question_id;
      delete a.updated_at;
      delete a.created_at;
      // var b = this.;
      // console.log(a);
      // return 'done';
      var arr = [];
      var arrfinal = {};
      arr.push(a);
      // return arr;
      var arrObject = [];
      var createobject = {};
      var pushallobject = [];
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "title";
      colorpickerobject["fieldType"] = "title";
      colorpickerobject["mapto"] = [];
      colorpickerobject["data"] = questionTitle;

      pushallobject.push(colorpickerobject);
      // console.log(arr);
      for (let index = 0; index < arr.length; index++) {
        for (let [key, value] of Object.entries(arr[index])) {
          // if(key !==)
          arrfinal = {};
          var newkey = "fieldname";

          arrfinal[newkey] = key;

          arrfinal["mapto"] = [];
          arrfinal["nested"] = [];
          //  console.log(key);
          if (typeof value == "string") {
            // console.log("data1", typeof(value));
            value.startsWith("http")
              ? (arrfinal["fieldType"] = "image")
              : (arrfinal["fieldType"] = "text");

            arrfinal["data"] = value;
          }
          if (typeof value == "number") {
            arrfinal["fieldType"] = "text";
            arrfinal["data"] = value;
          }
          // console.log(typeof value);
          if (typeof value == "object") {
            arrfinal["fieldType"] = "array";
            if (value.length == 0) {
              // console.log("at length 0",value);
              arrfinal["fieldType"] = "array";
              arrfinal["data"] = [];
            } else {
              for (let x = 0; x < value.length; x++) {
                createobject = {};
                var nested = "fieldname";
                var y = x + 1;
                createobject[nested] = key + "_" + y;
                createobject["mapto"] = [];
                // console.log(typeof(value[x]));
                var checkdatastart = value[x];
                if (typeof checkdatastart == "number") {
                  // console.log("number",value[x]);
                  checkdatastart = checkdatastart.toString();
                  createobject["fieldType"] = "text";
                  // createobject["fielddata"] = "string";
                } else if (typeof checkdatastart == "object") {
                  //  console.log("object",value[x]);
                  // console.log(checkdatastart);
                  checkdatastart = checkdatastart.value;
                  checkdatastart = checkdatastart.toString();
                  // console.log(checkdatastart);
                  // if (typeof checkdatastart == "string") {
                  checkdatastart.startsWith("http")
                    ? (createobject["fieldType"] = "image")
                    : (createobject["fieldType"] = "text");
                } else {
                  checkdatastart = checkdatastart;
                  checkdatastart.startsWith("http")
                    ? (createobject["fieldType"] = "image")
                    : (createobject["fieldType"] = "text");
                  // createobject["fieldType"] = "text";
                  // createobject["fielddata"] = "string";
                }

                createobject["data"] = value[x];

                arrfinal["nested"].push(createobject);
              }
            }
          }

          pushallobject.push(arrfinal);
        }
      }
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "background";
      colorpickerobject["mapto"] = [];
      colorpickerobject["values"] = backgrounddata;
      colorpickerobject["data"] = backgroundType;
      pushallobject.push(colorpickerobject);
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "QuestionType";
      colorpickerobject["mapto"] = [];
      colorpickerobject["feildType"] = "questionType";
      colorpickerobject["data"] = Question_Type;
      pushallobject.push(colorpickerobject);
      for (let a = 0; a < pushallobject.length; a++) {
        if (pushallobject[a].fieldType == "image") {
          this.previewImage.push(pushallobject[a]);
        }
        if (pushallobject[a].fieldType == "text") {
          this.previewText.push(pushallobject[a]);
        }
        if (pushallobject[a].fieldType == "array") {
          for (let y = 0; y < pushallobject[a].nested.length; y++) {
            if (typeof pushallobject[a].nested[y].data == "string") {
              this.previewArray.push(pushallobject[a].nested[y]);
            } else {
              var data = pushallobject[a].nested[y].data.value;
              data = data.toString();
              data.startsWith("http")
                ? (pushallobject[a].nested[y].data["fieldType"] = "image")
                : (pushallobject[a].nested[y].data["fieldType"] = "text");
              this.previewObject.push(pushallobject[a].nested[y]);
            }
          }
        }
        if (pushallobject[a].fieldType == "title") {
          this.previewTitle.push(pushallobject[a]);
        }
        if (pushallobject[a].data == "image") {
          this.previewbackground.push(pushallobject[a]);
        }
        if (pushallobject[a].data == "color") {
          this.previewbackground.push(pushallobject[a]);
        }
        if (pushallobject[a].feildType == "questionType") {
          this.previewQuestionType.push(pushallobject[a]);
        }
      }
      this.previewdata = pushallobject;
    },
    converttojson(jsondata) {
      this.jsonShowdata = JSON.stringify(jsondata, null, 2);
    },
    gettabledata: function () {
      this.show = true;
      axios
        .get(process.env.VUE_APP_API_URL + "/deleted-questions/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          if (get_response.data.message != "data according to user") {
            this.addDeleteQuestionHideButton = false;
            this.show = false;
          } else {
            this.addDeleteQuestionHideButton = true;
            this.table_ = get_response.data.deleteQuestion;
            this.table_data = get_response.data.deleteQuestion;
            this.show = false;
          }
        });
    },
    clickaddnewquestion: function (add_question_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/addquestiondata",
          add_question_data
        )
        .then((response) => {
          // console.log(response);
          // this.gettabledata();
          this.gettabledata();
        });
    },
    deletequestiondata(indexquestion) {
      this.deletequestion.id = indexquestion;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/deletequestion",
          this.deletequestion
        )
        .then((response) => {
          // console.log(response);
          // this.gettabledata();
          this.gettabledata();
        });
    },
    getDeletedQuestion() {
      this.show = true;
      setTimeout(() => {
        if (this.searchQuery.trim().length > 0) {
          // console.log('this.searchQuery',this.searchQuery)
          let finaldata = this.table_.filter((item) => {
            // console.log(item.title);
            if (item.questionNo && typeof item.title == "string") {
              // console.log()
              return this.searchQuery
                .toLowerCase()
                .split(" ")
                .every(
                  (v) =>
                    item.questionNo.toString().toLowerCase().includes(v) ||
                    item.title.toLowerCase().includes(v)
                );
            }
            //  if (item.title ) {
            //   return this.searchQuery
            //     .toLowerCase()
            //     .split(" ")
            //     .every((v) => item.title.toLowerCase().includes(v));
            // }
          });
          this.table_data = finaldata;
          this.show = false;
        } else {
          this.table_data = this.table_;
          this.show = false;
        }
      }, 2000);
    },
    editquestiondata(question) {
      this.isAddEditTypeSidebarActive = true;
      // alert(0);
      // console.log(question);
      this.editdata = question;
    },

    ClickEditQuestiondata(edit_question_data) {
      axios
        .post(process.env.VUE_APP_API_URL + "/editquestion", edit_question_data)
        .then((response) => {
          // console.log(response);
          // this.gettabledata();
          this.gettabledata();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

img.model_backgroundimage {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: -webkit-fill-available;
}
.modal_content {
  position: relative;
  z-index: 2;
}
</style>

<style lang="scss" scoped>
.padding_table {
  padding: 0.72rem 1.3rem !important;
}
// @import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" >
.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  padding: 0.72rem 1.4rem !important;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
